import {createMuiTheme} from '@material-ui/core/styles';
import {VictoryTheme} from 'victory';

declare module '@material-ui/core/styles/overrides' {
    interface ComponentNameToClassKey {
        MUIDataTable?: any;
    }
}
const defaultTheme = createMuiTheme({});

export const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#163E2D',
            light: '#F1F6F4',
        },
        secondary: {
            main: '#EE7D11',
            light: '#FEF2E0',
        },
        error: {
            main: '#B0001F',
            light: '#FDECEA',
        },
    },
    overrides: {
        MuiCardContent: {
            root: {
                '&:last-child': {
                    paddingBottom: '16px',
                },
            },
        },
        MuiCardActions: {
            root: {
                // Fix default padding which for some reason is not consistent with CardContent.
                padding: '16px',
            },
        },
        MuiExpansionPanel: {
            root: {
                border: '1px solid rgba(0, 0, 0, 0.12)',
                boxShadow: 'none',
            },
        },
        MuiExpansionPanelSummary: {
            content: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            },
            expandIcon: {
                '@media print': {
                    display: 'none',
                },
            },
        },
        MuiTableCell: {
            root: {
                '@media print': {
                    fontSize: '10px',
                },
            },
        },
        MuiStepIcon: {
            root: {
              color: '#EE7D11 !important', // Cambia a tu nuevo color
            },
            active: {
              color: '#EE7D11', // Cambia a tu nuevo color para el estado activo
            },
          },
        MuiLinearProgress: {
            root: {
                height: 10
            }
        
        }   

        
    },
});

type VictoryThemeDefinition = typeof VictoryTheme.material;
export const victoryTheme: VictoryThemeDefinition = {
    ...VictoryTheme.material,
    axis: {
        ...VictoryTheme.material.axis,
        style: {
            ...VictoryTheme.material.axis?.style,
            axis: {
                ...VictoryTheme.material.axis?.style?.axis,
                stroke: theme.palette.grey[300],
            },
            grid: {
                ...VictoryTheme.material.axis?.style?.grid,
                stroke: theme.palette.grey[300],
            },
            ticks: {
                ...VictoryTheme.material.axis?.style?.ticks,
                stroke: theme.palette.grey[300],
            },
            tickLabels: {
                ...VictoryTheme.material.axis?.style?.tickLabels,
                fontFamily: theme.typography.fontFamily,
                fill: theme.palette.grey[500],
            },
        },
    },
    line: {
        ...VictoryTheme.material.line,
        style: {
            ...VictoryTheme.material.line?.style,
            data: {
                ...VictoryTheme.material.line?.style?.data,
                stroke: theme.palette.primary.main,
            },
        },
    },
};
